import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { DbUtils } from '@core/utils';
import { computed, inject } from '@angular/core';
import { TransactionService } from '@smooved-lib/transaction/services/transaction.service';
import { firstValueFrom } from 'rxjs';
import { Transaction } from '@smooved-lib/transaction/classes/transaction';

type TransactionState = {
    isLoading: boolean;
    transaction?: Transaction;
};

const defaultState: TransactionState = {
    isLoading: false,
    transaction: null,
};

export const TransactionStore = signalStore(
    { providedIn: 'root' },
    withState(defaultState),
    withComputed((state) => ({
        transactionId: computed(() => DbUtils.getStringId(state.transaction())),
        leaverId: computed(() => DbUtils.getStringId(state.transaction()?.leaver)),
    })),
    withMethods((store, transactionService = inject(TransactionService)) => {
        const setTransaction = (transaction: Transaction) => {
            patchState(store, { transaction: new Transaction(transaction) });
        };
        const load = async (transactionId: string) => {
            patchState(store, { isLoading: true });
            try {
                const transaction = await firstValueFrom(transactionService.getTransaction(transactionId));
                patchState(store, {
                    isLoading: false,
                    transaction: new Transaction(transaction),
                });
            } catch (_) {
                patchState(store, { isLoading: false });
            }
        };
        const patch = async (transactionId: string, payload: any) => {
            patchState(store, { isLoading: true });
            try {
                await firstValueFrom(transactionService.updateTransaction(transactionId, payload));
                await load(transactionId);
            } catch (_) {
                patchState(store, { isLoading: false });
            }
        };

        return {
            setTransaction,
            load,
            patch,
        };
    })
);
